@tailwind components;

@layer components {
  .custom-checkbox {
    @apply appearance-none bg-white border border-gray-300 w-5 h-5 rounded-sm relative cursor-pointer inline-block text-center;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    font-size: 16px;
    line-height: 18px;
    margin-right: 8px;
    color: #fff;
    font-size: 12px;
    -webkit-box-shadow: inset 0 0 6px #ededed;
    box-shadow: inset 0 0 6px #ededed;
    -webkit-transition: border-color .25s ease 0s;
    transition: border-color .25s ease 0s;
  }

  .custom-checkbox:checked {
    @apply bg-white border border-gray-300 text-white;
  }

  .custom-checkbox:checked::after {
    content: '\2713'; /* Checkmark character */
    color: #000; /* Black color for the checkmark */
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 3px;
  }

  .custom-checkbox:checked:hover {
    @apply bg-white border border-gray-300;
  }
}