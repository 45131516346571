/* This Tailwind CSS snippet provides custom styling for markdown content to ensure consistent and 
visually appealing presentation. It includes styles for common HTML elements such as links (a), 
paragraphs (p), headings (h1, h2), and lists (ul, ol, li). Emphasis elements like strong are made 
bold and visually distinct, while list items are styled with spacing for readability. These styles 
can be applied to markdown-rendered content for a clean and modern appearance. */

@tailwind components;

@layer components {
  .markdown-description a {
    @apply underline;
  }

  .description p {
    @apply font-body text-20 leading-relaxed;
  }
  .services p {
    @apply font-body text-16 font-normal leading-5;
  }

  .markdown-description strong {
    @apply font-body font-bold text-black;
  }

  .markdown-description h1 {
    @apply mb-4 font-body text-4xl font-extrabold text-gray-900;
  }

  .markdown-description h2 {
    @apply mb-3 font-body text-3xl font-bold text-gray-900;
  }

  .markdown-description h3 {
    @apply mb-2 font-body text-2xl font-semibold text-gray-900;
  }

  .markdown-description h4 {
    @apply mb-2 font-body text-22 font-medium text-gray-900;
  }

  .services h4 {
    @apply my-7 font-body text-22 font-normal leading-6 text-gray-900;
  }

  .markdown-description ul {
    @apply mb-4 list-inside list-disc pl-24;
  }

  .markdown-description ul > li {
    @apply mb-2 list-disc font-body text-16 font-normal leading-5;
  }

  .markdown-description ul > li > ul {
    @apply mt-2 list-disc pl-8;
  }

  .markdown-description ul > li > ul > li {
    @apply mb-2 font-body text-16 font-normal leading-5;
  }

  .markdown-description ol {
    @apply mb-4;
  }

  .markdown-description ol > li {
    @apply mb-3 font-body text-16 font-normal leading-5;
  }

  .rubberInfo .markdown-description p,
  .summary-details.markdown-description p {
    @apply font-normal;
  }

  .link-green p a {
    @apply text-[#127e57];
  }

  .link-blue p a {
    @apply text-[#0575F8];
  }
  .link-no-underline p a {
    @apply no-underline;
  }

  .description .markdown-description p {
    @apply font-body text-16 font-normal leading-5;
  }
  .description .markdown-description p > strong {
    @apply font-semibold;
  }
  .description .markdown-description p > em {
    @apply font-normal italic leading-6;
  }
  .clubrepair .markdown-description p > strong {
    @apply font-medium;
  }

  .clubrepair .markdown-description p {
    @apply font-normal;
  }
  .servicesName .markdown-description h1 {
    @apply mb-30;
  }
  .servicesName .markdown-description h3 {
    @apply mb-30;
  }
  .termsAndConditionservicesheading,
  .privacyPolicyservicesheading,
  .aboutUsheading {
    @apply font-body text-[29px] md:text-[33px] lg:text-[36px];
  }
  .termsAndConditionservices .markdown-description h3 {
    @apply mb-[10px] font-body text-[22px] font-normal leading-[1.2] md:text-[25px] md:leading-[1.4] lg:text-[28px] lg:leading-[1.6];
  }

  .termsAndConditionservices .markdown-description h3 + p {
    @apply font-body text-16 font-semibold;
  }

  .termsAndConditionservices .markdown-description h3 + p + p {
    @apply max-w-full font-body text-16 font-normal;
  }

  .termsAndConditionservices .markdown-description h4 {
    @apply mb-20 font-body font-normal leading-[1];
  }

  .privacyPolicyservices .markdown-description h4 {
    @apply mt-20 font-body font-normal leading-[1];
  }

  .termsAndConditionservices .markdown-description p ~ h4 {
    @apply my-20 font-body font-normal leading-[1];
  }

  .termsAndConditionservices .markdown-description h4 ~ p,
  .privacyPolicyservices .markdown-description ul li {
    @apply font-body font-normal leading-[28px];
  }

  .privacyPolicyservices .markdown-description ol + p:has(> strong) {
    @apply mt-20 font-body;
  }

  .privacyPolicyservices .markdown-description h4 ~ p,
  .aboutUsservices.markdown-description p {
    @apply font-body font-normal leading-[1.2];
  }

  .termsAndConditionservices .markdown-description h4 + p a,
  .termsAndConditionservices .markdown-description h4 + p + p a,
  .termsAndConditionservices .markdown-description h4 + p + p + p + p + p + p a,
  .termsAndConditionservices .markdown-description h4 + p + p + p + p + p + p + p a {
    @apply font-body text-[#127e57] no-underline;
  }

  .termsAndConditionservices .markdown-description p + p + p a,
  .termsAndConditionservices .markdown-description h4 + p + p + p + p + p + p + p + h4 + p a {
    @apply font-body text-[#127e57] underline;
  }

  .insurancefaq button div {
    @apply p-[7px] font-body transition-all duration-300 ease-in-out hover:bg-black hover:text-white;
  }

  /* Apply animation when accordion is opened */
  .insurancefaq button[aria-expanded='true'] {
    background-color: black !important;
    color: white !important;
  }

  .insurancefaq button div p {
    @apply text-left font-body font-normal;
  }

  .faqicon {
    @apply text-black transition-all duration-300;
  }

  .group:hover .faqicon {
    @apply text-white;
  }

  .faqans.markdown-description p {
    @apply mb-[10px] font-body font-normal;
  }

  .customservices div div div div div p a {
    @apply text-[#127e57];
  }

  .customservices div div div p {
    @apply text-left font-body font-semibold text-black;
  }

  .customservices div div div div div p {
    @apply font-body font-normal;
  }

  /* When the accordion is open */
  [aria-expanded='true'] .faqicon {
    background-color: black !important;
    color: white !important;
  }

  .Terms-Condition-Section .markdown-description ul li {
    @apply font-body font-normal leading-[1.7];
  }
  .Terms-Condition-Section .markdown-description ul {
    @apply mt-[10px];
  }

  .Terms-Condition-Section .markdown-description p strong {
    @apply font-body font-semibold;
  }

  .Terms-Condition-Section .markdown-description p {
    @apply mb-[20px] font-body font-normal;
  }
  .markdown-description.privacy-policy p {
    @apply font-body text-16 font-normal leading-relaxed;
  }

  .shipping-delivery.markdown-description p {
    @apply font-normal md:text-[17px] md:leading-[19px];
  }

  /* Antislavery */

  .antislaveryservicesheading {
    @apply mb-25 font-body text-23 normal-case tracking-normal md:text-30 lg:text-34 lg:leading-8;
  }

  .antiSlaveryservices.markdown-description h2 {
    @apply mb-20 font-body text-22 font-normal md:text-25 lg:text-28;
  }

  .antiSlaveryservices.markdown-description h4 {
    @apply mb-10 font-body text-20 font-normal md:text-21 lg:text-22;
  }

  .antiSlaveryservices.markdown-description p {
    @apply font-normal;
  }

  .antiSlaveryservices.markdown-description ul > li {
    @apply mb-0 leading-7;
  }
  .markdown-description.privacy-policy p {
    @apply font-body text-16 font-normal leading-relaxed;
  }
  .privacy-policy ul li {
    @apply mt-[10px];
  }

  .shopOnlineCollectservices .markdown-description h3 {
    @apply mb-20 font-body text-28 font-normal;
  }
  .shopOnlineCollectservices .markdown-description p {
    @apply font-body text-16 font-normal;
  }
  .shopOnlineCollectHeading {
    @apply font-heading text-33;
  }

  .return .markdown-description h4 {
    @apply mb-20 text-28;
  }
  .return .markdown-description h4 + p {
    @apply mb-20 text-16 font-normal;
  }
  .return.markdown-description h4 + p + p > strong {
    @apply text-16 font-semibold;
  }
  .return.markdown-description h4 + p + p + p {
    @apply mb-20 text-16 font-normal;
  }

  .return.markdown-description h4 + p + p + p + p > strong {
    @apply text-16 font-semibold;
  }
  .return .markdown-description h4 + p + p + p + p + p {
    @apply mb-20 text-16 font-normal;
  }
  .return .markdown-description h4 + p + p + p + p + p + p > strong {
    @apply text-16 font-semibold;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p {
    @apply text-16 font-normal;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p > strong > a {
    @apply text-16 font-semibold no-underline;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p + p {
    @apply text-16 font-normal;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p + p + p {
    @apply text-16 font-normal;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p + p + p {
    @apply mb-20 text-16 font-normal;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p + p + p + h4 + p {
    @apply mb-0 text-16 font-normal;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p + p + p + h4 + p + ul > li {
    @apply text-16 font-normal leading-7;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p + p + p + h4 + p + ul + p > strong {
    @apply text-16 font-semibold;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p + p + p + h4 + p + ul + p {
    @apply mb-20 text-16 font-normal;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p + p + p + h4 + p + ul + p {
    @apply mb-20 text-16 font-normal;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p + p + p + h4 + p + ul + p + h3 {
    @apply mb-20 text-28 font-normal;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p + p + p + h4 + p + ul + p + h3 + p {
    @apply text-16 font-normal;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p + p + p + h4 + p + ul + p + h3 + p {
    @apply text-16 font-normal;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p + p + p + h4 + p + ul + p + h3 + p + ul > li {
    @apply text-16 font-normal leading-7;
  }
  .return.markdown-description h4 + p + p + p + p + p + p + p + p + p + h4 + p + ul + p + h3 + p + ul + p {
    @apply mt-20 text-16 font-normal leading-5;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p + p + p + h4 + p + ul + p + h3 + p + ul + p + p {
    @apply text-16 font-normal leading-5;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p + p + p + h4 + p + ul + p + h3 + p + ul + p + p + p {
    @apply text-16 font-normal leading-5;
  }
  .return .markdown-description h4 + p + p + p + p + p + p + p + p + p + h4 + p + ul + p + h3 + p + ul + p + p + p + p {
    @apply text-16 font-normal leading-5;
  }
  .return
    .markdown-description
    h4
    + p
    + p
    + p
    + p
    + p
    + p
    + p
    + p
    + p
    + h4
    + p
    + ul
    + p
    + h3
    + p
    + ul
    + p
    + p
    + p
    + p
    + p
    + p
    + p {
    @apply text-16 font-normal leading-5;
  }
  .return
    .markdown-description
    h4
    + p
    + p
    + p
    + p
    + p
    + p
    + p
    + p
    + p
    + h4
    + p
    + ul
    + p
    + h3
    + p
    + ul
    + p
    + p
    + p
    + p
    + p
    + p
    + p
    + p {
    @apply text-16 font-normal leading-5;
  }
  .return .markdown-description h3 + p + ul + p + p + p + p + p {
    @apply text-16 font-normal leading-5;
  }
  .return .markdown-description h3 + p + ul + p + p + p + p + p + p {
    @apply text-16 font-normal leading-5;
  }
  .return .markdown-description h3 + p + ul + p + p + p + p + p + p + p {
    @apply mb-20 text-16 font-normal leading-5;
  }
  .shipping-delivery .markdown-description p {
    @apply text-16 font-normal;
  }

  .shipping-delivery .markdown-description p > strong {
    @apply text-16 !font-semibold;
  }

  .shipping-delivery .markdown-description.inStoreOptions p {
    @apply text-16 font-normal leading-5;
  }
  .shipping-delivery .markdown-description .inStoreOptions h4 {
    @apply text-21 font-normal;
  }
  .shipping-delivery .ukdeliveries p:first-of-type {
    @apply text-16 font-normal leading-5;
  }

  .shipping-delivery .ukdeliveries p:nth-of-type(2) {
    @apply text-16 font-normal leading-7;
  }
  .shipping-delivery .internationalDeliveries .markdown-description p {
    @apply text-16 font-normal leading-5;
  }
  .shipping-delivery .additional-info.markdown-description p {
    @apply text-16 font-normal leading-5;
  }
  .additional-info .markdown-description p:nth-of-type(n + 2):nth-of-type(-n + 7) {
    @apply leading-7;
  }
  .shipping-delivery .ukdeliveries .markdown-description p:nth-of-type(n + 3) {
    @apply text-16 font-normal leading-7;
  }

  .shipping-delivery .additional-detail .markdown-description p {
    @apply text-16 font-normal;
  }
  .WEEE .markdown-description li {
    @apply text-16 leading-7;
  }
  .WEEE .markdown-description p > a {
    @apply font-normal text-black no-underline;
  }
  .WEEE .markdown-description h2 {
    @apply text-23 md:text-[30px] lg:text-[34px];
  }
  .secureShoppingservicesheading {
    @apply font-body text-29 md:text-33 lg:text-36;
  }

  .secureShoppingservices .markdown-description p {
    @apply font-normal leading-5 xl:pr-100;
  }

  .secureShoppingservices .markdown-description h3 {
    @apply mb-20 font-body text-22 font-normal md:text-25 lg:text-28;
  }

  .secureShoppingservices .markdown-description h3 + p {
    @apply font-normal leading-5;
  }

  .clubGuaranteeservicesheading {
    @apply font-body text-29 md:text-33 lg:text-36;
  }
}
