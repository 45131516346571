@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./components/slider.css');
@import url('./components/default-loader.css');
@import url('./components/quick-search.css');
@import url('./components/modal.css');
@import url('./components/loading.css');
@import url('./components/markdown.css');
@import url('./components/custom-checkbox.css');
@font-face {
  font-family: 'Urw-din';
  src: url('../fonts/URWDIN-Thin.eot');
  src: local('URW DIN Thin'), local('URWDIN-Thin'), url('../fonts/URWDIN-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/URWDIN-Thin.woff2') format('woff2'), url('../fonts/URWDIN-Thin.woff') format('woff'),
    url('../fonts/URWDIN-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Urw-din';
  src: url('../fonts/URWDIN-XLight.eot');
  src: local('URW DIN XLight'), local('URWDIN-XLight'),
    url('../fonts/URWDIN-XLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/URWDIN-XLight.woff2') format('woff2'), url('../fonts/URWDIN-XLight.woff') format('woff'),
    url('../fonts/URWDIN-XLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Urw-din';
  src: url('../fonts/URWDIN-Light.eot');
  src: local('URW DIN Light'), local('URWDIN-Light'),
    url('../fonts/URWDIN-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/URWDIN-Light.woff2') format('woff2'), url('../fonts/URWDIN-Light.woff') format('woff'),
    url('../fonts/URWDIN-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Urw-din';
  src: url('../fonts/URWDIN-Regular.eot');
  src: local('URW DIN Regular'), local('URWDIN-Regular'),
    url('../fonts/URWDIN-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/URWDIN-Regular.woff2') format('woff2'), url('../fonts/URWDIN-Regular.woff') format('woff'),
    url('../fonts/URWDIN-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Urw-din';
  src: url('../fonts/URWDIN-Medium.eot');
  src: local('URW DIN Medium'), local('URWDIN-Medium'),
    url('../fonts/URWDIN-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/URWDIN-Medium.woff2') format('woff2'), url('../fonts/URWDIN-Medium.woff') format('woff'),
    url('../fonts/URWDIN-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Urw-din';
  src: url('../fonts/URWDIN-Demi.eot');
  src: local('URW DIN Demi'), local('URWDIN-Demi'), url('../fonts/URWDIN-Demi.eot?#iefix') format('embedded-opentype'),
    url('../fonts/URWDIN-Demi.woff2') format('woff2'), url('../fonts/URWDIN-Demi.woff') format('woff'),
    url('../fonts/URWDIN-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Urw-din';
  src: url('../fonts/URWDIN-Bold.eot');
  src: local('URW DIN Bold'), local('URWDIN-Bold'), url('../fonts/URWDIN-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/URWDIN-Bold.woff2') format('woff2'), url('../fonts/URWDIN-Bold.woff') format('woff'),
    url('../fonts/URWDIN-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Urw-din';
  src: url('../fonts/URWDIN-Black.eot');
  src: local('URW DIN Black'), local('URWDIN-Black'),
    url('../fonts/URWDIN-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/URWDIN-Black.woff2') format('woff2'), url('../fonts/URWDIN-Black.woff') format('woff'),
    url('../fonts/URWDIN-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Urw-din';
  src: url('../fonts/URWDIN-ThinItalic.eot');
  src: local('URW DIN Thin Italic'), local('URWDIN-ThinItalic'),
    url('../fonts/URWDIN-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/URWDIN-ThinItalic.woff2') format('woff2'), url('../fonts/URWDIN-ThinItalic.woff') format('woff'),
    url('../fonts/URWDIN-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Urw-din';
  src: url('../fonts/URWDIN-XLightItalic.eot');
  src: local('URW DIN XLight Italic'), local('URWDIN-XLightItalic'),
    url('../fonts/URWDIN-XLightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/URWDIN-XLightItalic.woff2') format('woff2'), url('../fonts/URWDIN-XLightItalic.woff') format('woff'),
    url('../fonts/URWDIN-XLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Urw-din';
  src: url('../fonts/URWDIN-LightItalic.eot');
  src: local('URW DIN Light Italic'), local('URWDIN-LightItalic'),
    url('../fonts/URWDIN-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/URWDIN-LightItalic.woff2') format('woff2'), url('../fonts/URWDIN-LightItalic.woff') format('woff'),
    url('../fonts/URWDIN-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Urw-din';
  src: url('../fonts/URWDIN-Regular.eot');
  src: local('URW DIN Regular'), local('URWDIN-Regular'),
    url('../fonts/URWDIN-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/URWDIN-Regular.woff2') format('woff2'), url('../fonts/URWDIN-Regular.woff') format('woff'),
    url('../fonts/URWDIN-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Urw-din';
  src: url('../fonts/URWDIN-MediumItalic.eot');
  src: local('URW DIN Medium Italic'), local('URWDIN-MediumItalic'),
    url('../fonts/URWDIN-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/URWDIN-MediumItalic.woff2') format('woff2'), url('../fonts/URWDIN-MediumItalic.woff') format('woff'),
    url('../fonts/URWDIN-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Urw-din';
  src: url('../fonts/URWDIN-DemiItalic.eot');
  src: local('URW DIN Demi Italic'), local('URWDIN-DemiItalic'),
    url('../fonts/URWDIN-DemiItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/URWDIN-DemiItalic.woff2') format('woff2'), url('../fonts/URWDIN-DemiItalic.woff') format('woff'),
    url('../fonts/URWDIN-DemiItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Urw-din';
  src: url('../fonts/URWDIN-BoldItalic.eot');
  src: local('URW DIN Bold Italic'), local('URWDIN-BoldItalic'),
    url('../fonts/URWDIN-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/URWDIN-BoldItalic.woff2') format('woff2'), url('../fonts/URWDIN-BoldItalic.woff') format('woff'),
    url('../fonts/URWDIN-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Urw-din';
  src: url('../fonts/URWDIN-BlackItalic.eot');
  src: local('URW DIN Black Italic'), local('URWDIN-BlackItalic'),
    url('../fonts/URWDIN-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/URWDIN-BlackItalic.woff2') format('woff2'), url('../fonts/URWDIN-BlackItalic.woff') format('woff'),
    url('../fonts/URWDIN-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.woff2') format('woff2'), url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.woff') format('woff'), url('../fonts/icomoon.svg#bs-icomoont') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'wash-iconfont';
  src: url('../fonts/washicons.eot');
  src: url('../fonts/washicons.eot?#iefix') format('embedded-opentype'), url('../fonts/washicons.woff2') format('woff2'),
    url('../fonts/washicons.ttf') format('truetype'), url('../fonts/washicons.woff') format('woff'),
    url('../fonts/washicons.svg#bs-iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

html {
  @apply font-html;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
:after,
:before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

*,
:focus {
  outline: 0;
}

body {
  margin: 0;
  width: 100%;
  @apply font-body;
  @apply text-primary-black;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-heading;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  @apply underline-offset-[0.25em];
}

.react-datepicker__header {
  @apply bg-white !important;
  @apply border-b-0 !important;
  @apply py-0 !important;
}

.react-datepicker__day-name {
  @apply font-bold;
  @apply text-16;
}

.react-datepicker__day {
  @apply font-light;
  @apply text-16;
}

.react-datepicker__day:not(.react-datepicker__day--disabled):hover {
  @apply bg-transparent !important;
  @apply text-black/30 !important;
  @apply underline;
}

.react-datepicker__day--selected {
  @apply bg-transparent !important;
  @apply font-light !important;
  @apply text-primary-black !important;
}

h1 {
  @apply font-heading;
  font-size: 29px;
  line-height: 29px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 30px;
}

@media (min-width: 768px) {
  .h1 {
    font-size: 33px;
    line-height: 33px;
  }
}

@media (min-width: 992px) {
  .h1 {
    font-size: 36px;
    line-height: 36px;
  }
}

.h2 {
  @apply font-heading;
  font-size: 23px;
  line-height: 26px;
  font-weight: 700;
  margin: 0 0 25px;
}

@media (min-width: 768px) {
  .h2 {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (min-width: 992px) {
  .h2 {
    font-size: 34px;
    line-height: 34px;
  }
}

.h3 {
  @apply font-heading;
  font-size: 22px;
  line-height: 22px;
  font-weight: 400;
  margin: 0 0 20px;
}

@media (min-width: 768px) {
  .h3 {
    font-size: 25px;
    line-height: 25px;
  }
}

@media (min-width: 992px) {
  .h3 {
    font-size: 28px;
    line-height: 28px;
  }
}

.h4 {
  @apply font-heading;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  margin: 0 0 10px;
}

@media (min-width: 768px) {
  .h4 {
    font-size: 21px;
    line-height: 21px;
  }
}

@media (min-width: 992px) {
  .h4 {
    font-size: 22px;
    line-height: 22px;
  }
}

.h5 {
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin: 0 0 10px;
}

@media (min-width: 768px) {
  .h5 {
    font-size: 19px;
    line-height: 19px;
  }
}

@media (min-width: 992px) {
  .h5 {
    font-size: 20px;
    line-height: 20px;
  }
}

article,
aside,
figcaption,
figure,
footer,
header,
main,
nav,
section {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a,
p,
li,
div,
span,
button {
  @apply font-body;
  font-weight: 500;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

ol,
ol li,
ul,
ul li {
  list-style: none;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type='reset'],
[type='submit'],
button,
html [type='button'] {
  -webkit-appearance: button;
}

[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

.icon {
  font-family: icomoon !important;
}

.icon,
.washicon {
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account-header:before {
  content: '\e815';
  font-size: 1.2em;
}

.icon-account:before {
  content: '\e815';
}

.icon-clubhouse:before {
  content: '\e92b';
}

.icon-new-arrow_down:before {
  content: '\e92c';
}

.icon-new-arrow_left:before {
  content: '\e801';
}

.icon-new-arrow_right:before {
  content: '\e802';
}

.icon-new-arrow_up:before {
  content: '\e92f';
}

.icon-instagram:before {
  content: '\e80d';
}

.icon-linkedin:before {
  content: '\e80e';
}

.icon-pound-sign:before {
  content: '\e928';
}

.icon-fitting:before {
  content: '\e813';
}

.icon-trophy-2:before {
  content: '\e925';
}

.icon-trophy-1:before {
  content: '\e926';
}

.icon-play-icon:before {
  content: '\e924';
}

.icon-arrow_down:before {
  content: '\e800';
}

.icon-arrow_left:before {
  content: '\e801';
}

.icon-arrow_right:before {
  content: '\e802';
}

.icon-arrow_up:before {
  content: '\e91a';
}

.icon-attention:before {
  content: '\e904';
}

.icon-basket:before {
  content: '\e804';
}

.icon-basket-header:before {
  content: '\e804';
  font-size: 1.2em;
}

.icon-chat:before {
  content: '\e906';
}

.icon-check:before {
  content: '\e902';
}

.icon-club:before {
  content: '\e908';
}

.icon-compare:before {
  content: '\e920';
}

.icon-cross:before {
  content: '\e903';
}

.icon-currentlocation:before {
  content: '\e922';
}

.icon-deliverystore:before {
  content: '\e90d';
}

.icon-deliveryhome:before,
.icon-storecollection:before {
  content: '\e923';
}

.icon-heart:before {
  content: '\e900';
}

.icon-heart-header:before {
  content: '\e900';
  font-size: 1.2em;
}

.icon-info:before {
  content: '\e80c';
}

.icon-loader:before {
  content: '\e805';
}

.icon-location:before {
  content: '\e80f';
}

.icon-marker:before {
  content: '\e912';
}

.icon-menu:before {
  content: '\e913';
}

.icon-filter:before {
  content: '\e939';
}

.icon-phone:before {
  content: '\e92b';
}

.icon-plus:before {
  content: '\e807';
}

.icon-minus:before {
  content: '\e806';
}

.icon-reserve:before {
  content: '\e92e';
}

.icon-search:before {
  content: '\e901';
}

.icon-zoom:before {
  content: '\e919';
}

.icon-speech:before {
  content: '\e91a';
}

.icon-pricematch:before {
  content: '\e91b';
}

.icon-star:before {
  content: '\e91c';
}

.icon-twitter:before {
  content: '\e814';
}

.icon-facebook:before {
  content: '\e80a';
}

.icon-youtube:before {
  content: '\e816';
}

.icon-google:before {
  content: '\e920';
}

.icon-mail:before {
  content: '\e938';
}

.washicon-a1:before {
  content: '\e900';
}

.washicon-a2:before {
  content: '\e901';
}

.washicon-a3:before {
  content: '\e902';
}

.washicon-a4:before {
  content: '\e903';
}

.washicon-a5:before {
  content: '\e904';
}

.washicon-a6:before {
  content: '\e905';
}

.washicon-a7:before {
  content: '\e906';
}

.washicon-a8:before {
  content: '\e907';
}

.washicon-a9:before {
  content: '\e908';
}

.washicon-a10:before {
  content: '\e909';
}

.washicon-a11:before {
  content: '\e90a';
}

.washicon-a12:before {
  content: '\e90b';
}

.washicon-a13:before {
  content: '\e90c';
}

.washicon-a14:before {
  content: '\e90d';
}

.washicon-a15:before {
  content: '\e90e';
}

.washicon-b1:before {
  content: '\e90f';
}

.washicon-b2:before {
  content: '\e910';
}

.washicon-b3:before {
  content: '\e911';
}

.washicon-b4:before {
  content: '\e912';
}

.washicon-c1:before {
  content: '\e913';
}

.washicon-c2:before {
  content: '\e914';
}

.washicon-c3:before {
  content: '\e915';
}

.washicon-c4:before {
  content: '\e916';
}

.washicon-c5:before {
  content: '\e917';
}

.washicon-c6:before {
  content: '\e918';
}

.washicon-c7:before {
  content: '\e919';
}

.washicon-c8:before {
  content: '\e91a';
}

.washicon-c9:before {
  content: '\e91b';
}

.washicon-c10:before {
  content: '\e91c';
}

.washicon-d1:before {
  content: '\e91d';
}

.washicon-d2:before {
  content: '\e91e';
}

.washicon-d3:before {
  content: '\e91f';
}

.washicon-d4:before {
  content: '\e920';
}

.washicon-e1:before {
  content: '\e921';
}

.washicon-e2:before {
  content: '\e922';
}

.washicon-e3:before {
  content: '\e923';
}

.washicon-f1:before {
  content: '\e924';
}

.washicon-f2:before {
  content: '\e925';
}

.washicon-f3:before {
  content: '\e926';
}

[type='checkbox'],
[type='radio'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

[hidden],
template {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
  vertical-align: top;
}

strong {
  font-weight: 600;
}

i {
  font-style: italic;
}

u {
  text-decoration: underline;
}

sup {
  font-size: 11px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

input:focus {
  box-shadow: none !important;
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}

input.placeholder-color-default::placeholder {
  color: #999999;
}

input.placeholder-color-focus::placeholder {
  color: #9f9f9f;
}

a {
  color: #1e1e1e;
  -webkit-transition: color 0.25s ease 0s, background-color 0.25s ease 0s, border-color 0.25s ease 0s,
    opacity 0.25s ease 0s;
  transition: color 0.25s ease 0s, background-color 0.25s ease 0s, border-color 0.25s ease 0s, opacity 0.25s ease 0s;
}

a:hover {
  color: #666;
}

@layer utilities {
  .text-shadow {
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  }
}

.apple-pay-button {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: pay; /* Use any supported button type. */
  width: 100%;
  background: #000;
  color: #fff;
  border-radius: 4px;
}

.apple-pay-button button {
  width: 100%;
  height: 44px;
}

.apple-pay-button-black {
  -apple-pay-button-style: black;
}
.apple-pay-button-white {
  -apple-pay-button-style: white;
}
.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}
.account-icon:after,
.wishlist-icon:after,
.cart-icon:after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  bottom: 3px;
  background: #7ce3be;
  position: absolute;
  left: 50%;
  -webkit-transition: width 0.45s, margin 0.45s;
  transition: width 0.45s, margin 0.45s;
}
.cart-icon:after {
  bottom: -3px;
}
.account-icon:hover:after {
  width: 35px;
  margin-left: -38%;
}
.wishlist-icon:hover:after {
  width: 35px;
  margin-left: -55%;
}
.cart-icon:hover:after {
  width: 35px;
  margin-left: -65%;
}
.link-featured {
  background-color: #336548;
}
.link-featured a p {
  color: #d9c47f !important;
}
.link-featured:hover a p {
  color: black !important;
}
.bg-primary {
  background-color: #1e1e1e;
}
.input-placeholder-black::placeholder {
  color: #1e1e1e;
}
.mobile-main-menu {
  height: calc(100vh - 62px) !important;
}

@media (max-width: 768px) {
  .adyen-checkout__iframe.adyen-checkout__iframe--threeDSIframe,
  .adyen-checkout__threeds2__challenge.adyen-checkout__threeds2__challenge--02 {
    width: 100% !important;
  }
}

.search-container {
  background-color: #f6f6f6;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}
.footer-usermenu {
  background-color: #f6f6f6;
}

.footer-social-bg {
  background: url(../images/footer_bg.jpg);
}

.bg-free-delivery-gradient-custom {
  background-image: linear-gradient(90deg, #2dac70, #3bdf0e);
}

.padding-left-custom {
  padding-right: 125px !important;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.01);
  }
  50% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}

/* Hide scrollbar by default */
.ag__cc__wrapper {
  scrollbar-width: none;
  /* Firefox */
  overflow-y: hidden;
}

/* Show scrollbar when hovering (desktop) or scrolling (mobile) */
.ag__cc__wrapper:hover,
.ag__cc__wrapper:active,
.ag__cc__wrapper:focus-within {
  scrollbar-width: thin;
}

/* Custom scrollbar for WebKit browsers */
.ag__cc__wrapper::-webkit-scrollbar {
  width: 0px;
  /* Hidden by default */
  height: 0px;
}

/* Always show scrollbar on mobile */
@media (max-width: 768px) {
  .ag__cc__wrapper {
    scrollbar-width: thin !important;
    overflow-x: scroll !important;
  }

  .ag__cc__wrapper::-webkit-scrollbar {
    width: 6px !important;
    height: 4px !important;
  }
}

/* Visible scrollbar when hovered (desktop) or scrolling (mobile) */
.ag__cc__wrapper:hover::-webkit-scrollbar,
.ag__cc__wrapper:active::-webkit-scrollbar,
.ag__cc__wrapper:focus-within::-webkit-scrollbar {
  width: 6px;
  /* Adjust width */
  height: 4px;
  /* Adjust height */
}

/* Change scrollbar track color */
.ag__cc__wrapper::-webkit-scrollbar-track {
  background: #e2e2e2;
  /* Light grey */
  border-radius: 10px;
}

/* Change scrollbar thumb color */
.ag__cc__wrapper::-webkit-scrollbar-thumb {
  background: #e2e2e2;
  /* Darker grey */
  border-radius: 10px;
}
