.slider {
  position: static;
}
.slider__container {
  position: relative;
  overflow: visible;
}

.slider-main-container {
  position: relative;
  overflow: visible;
}

.slider__container--fit {
  width: fit-content;
}
.slider__container--with-thumbs {
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 25px;
  height: 480px;
}

.slider__slides {
  position: relative;
  overflow: hidden;
  padding: 0 20px;
}
.slider__thumbs {
  position: relative;
  box-sizing: border-box;
  height: 480px;
  width: 80px;
}
.swiper-pagination-bullet.slider__bullet--active {
  background: gray;
  opacity: 1;
}
.slider__thumb {
  height: 80px;
  width: 80px;
}

.slider_arrow {
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  transform: translateY(-50%);
}

.slider_arrow .arrow {
  display: block;
  border: solid #494949;
  border-width: 0 2px 2px 0;
  padding: 10px;
  width: 0;
  height: 0;
}

.slider_arrow.slider_arrow_solid {
  padding: 16px 14px;
  width: 38px;
  overflow: hidden;
  display: flex;
  transition: 150ms ease-out;
}

.slider_arrow.slider_arrow_solid.swiper-button-disabled .arrow {
  border-color: #d1d1d1;
}

.slider_arrow.slider_arrow_solid:not(.swiper-button-disabled):hover {
  background: #212121;
}

.slider_arrow.slider_arrow_solid .arrow {
  border-color: #212121;
  border-width: 0 2px 2px 0;
}

.slider_arrow.slider_arrow_solid:not(.swiper-button-disabled):hover .arrow {
  border-color: white;
}

.slider_arrow_next.slider_arrow_solid {
  justify-content: flex-end;
}

.slider_arrow_prev.slider_arrow_solid {
  justify-content: flex-start;
}

.slider_arrow_prev {
  left: -40px;
}

.slider_arrow_prev .arrow {
  transform: rotateZ(135deg);
}

.slider_arrow_next {
  right: -40px;
}

.slider_arrow_next .arrow {
  transform: rotateZ(-45deg);
}

.slider_arrow_container.slider_arrow_solid {
  background-color: #212121;
  padding: 12px;
}

.slider_arrow_prev.slider_arrow_inner {
  left: 10px;
}

.slider_arrow_next.slider_arrow_inner {
  right: 10px;
}

.swiper-button-disabled {
  cursor: default;
}

.swiper-button-disabled .arrow {
  border-color: #d1d1d1;
}

.swiper-slide {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

/* Custom Range Slider Styles */
/* === Ensure the slider is visible === */
.A-RCEa_spectrum-Slider {
  width: 100% !important;
}

/* === Track (Inactive background) === */
.A-RCEa_spectrum-Slider-track {
  height: 8px !important;
  border-radius: 4px !important;
  background-color: #d1d5db !important; /* Light gray */
  transition: background-color 0.2s ease;
}

/* === Active Track (The part being adjusted) === */
.A-RCEa_spectrum-Slider-track:nth-of-type(3) {
  background: linear-gradient(to right, #127e57, #127e57) !important; /* Green */
}

/* === Slider Handle (Thumb) === */
.A-RCEa_spectrum-Slider-handle {
  width: 22px !important;
  height: 22px !important;
  border-radius: 50% !important;
  background-color: #fff !important;
  border: 2px solid #127e57 !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important;
  transform: translate(-35%, -35%) !important;
  /* transition: transform 0.1s ease, box-shadow 0.2s ease; */
}

/* === Hover & Drag Effects === */
.A-RCEa_spectrum-Slider-handle:hover,
.A-RCEa_spectrum-Slider-handle:active {
  /* transform: scale(1.1) !important; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important;
}
