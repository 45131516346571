@keyframes grow {
  0%,
  100% {
    height: 18px;
    background-color: #ccc;
  }
  50% {
    height: 36px;
    background-color: #007034;
  }
}

.loading-container {
  display: flex;
  align-items: flex-end;
  height: 40px; /* Maximum height of the animation */
  justify-content: center;
}

.loading-bar {
  width: 6px;
  animation: grow 0.8s infinite;
}

.loading-bar:nth-child(1) {
  animation-delay: 100ms;
}

.loading-bar:nth-child(2) {
  animation-delay: 200ms;
}

.loading-bar:nth-child(3) {
  animation-delay: 300ms;
}

.loading-bar:nth-child(4) {
  animation-delay: 400ms;
}

.loading-bar:nth-child(5) {
  animation-delay: 500ms;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-circle {
  border: 3px solid #3498db;
  border-top: 3px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}